
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import ChooseFleetModal from "@/components/Planning/ChooseFleetModal.vue";
import CrewOnBoard from "@/components/Planning/CrewOnBoard.vue";
import { CrewStatus, Readliness } from "@/enums";
import crewBoardHttp from "@/http/crewBoard";
import { helperMixin } from "@/mixins";
import { FleetPaginate } from "@/models/fleet";
import selectionStore from "@/store/selection";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    LoadingPage,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    BaseFormGroup,
    ChooseFleetModal,
    BaseButtonPrimary,
    ActionTableColumn,
    DropdownItem,
    CrewOnBoard,
  },

  mixins: [helperMixin],

  setup() {
    return {
      crewOnBoard: ref(null) as unknown as typeof CrewOnBoard,
    };
  },

  computed: {
    selectionStore,
    crewBoardHttp,
  },

  data() {
    return {
      exportingExcelLoading: false,
      CrewStatus,
      Readliness,
      showChooseFleetModal: false,
      fleetTabs: [] as FleetPaginate[],
      activeTabId: "" as any,
    };
  },

  mounted() {
    this.selectionStore.fetch();
  },

  methods: {
    async exportToExcel() {
      this.exportingExcelLoading = true;

      await this.crewBoardHttp.exportPlanning({ ...this.crewOnBoard.filter });

      setTimeout(() => {
        this.exportingExcelLoading = false;
      }, 1000);
    },

    async setActiveTabId(id: any) {
      if (this.activeTabId === id || !id) return;

      this.activeTabId = id;

      await this.$nextTick();

      this.crewOnBoard.loading = false;
      this.crewOnBoard.filter.fleet_id = id;

      if (this.appStore.state.activeMenu) {
        this.appStore.state.activeMenu.breadcrumbs = [
          this.fleetTabs.find((fleet) => fleet.id === id)?.ship_name as string,
        ];
      }
    },

    removeTab(index: number) {
      if (this.activeTabId === this.fleetTabs[index].id) {
        this.crewOnBoard.rows = [];

        this.setActiveTabId(
          this.fleetTabs[index + 1]?.id ?? this.fleetTabs[index - 1]?.id
        );
      }

      this.fleetTabs.splice(index, 1);
    },

    addTab(fleet: FleetPaginate) {
      if (!this.fleetTabs.find((f) => f.id === fleet.id)) {
        this.fleetTabs.push(fleet);
        this.setActiveTabId(fleet.id);
      }
    },
  },
});
