
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import fleetHttp from "@/http/fleet";
import { baseModal, helperMixin } from "@/mixins";
import { FleetPaginate } from "@/models/fleet";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["yes"],
  mixins: [helperMixin, baseModal],
  computed: {
    fleetHttp,
  },
  components: {
    BaseModal,
    BaseButtonDefault,
    BaseButtonDanger,
    BaseButtonPrimary,
    BaseFormGroup,
  },
  data() {
    return {
      fleetId: "",
      choosedFleet: null as null | FleetPaginate,
    };
  },
  methods: {
    onSelectChange(val: FleetPaginate) {
      this.choosedFleet = val;
    },

    yes() {
      this.$emit("yes", this.choosedFleet);
      this.value = false;
      this.fleetId = "";
    },
  },
});
